// CATD TABS IN MY CARD MODAL
.nav-tabs.card-tabs {
    top: 0;
    border: 0;
    padding: 0;
    z-index: 8;
    position: sticky;
    background-color: #fff;
    box-shadow: 0px 1px 5px 1px rgba(64, 60, 67, 0.16);

    .nav-item {
        .nav-link {
            border: 0;
            color: #8A8A8A;
            border-radius: 0;
            position: relative;
            background-color: transparent;

            &:hover,
            &.active {
                color: #ffffff;
                background-color: #dc3545;
            }
        }
    }

}

.main-category-wrapper {
    z-index: 2;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    background-color: #fbfcfe;
    border-bottom: 1px solid #e2e5ec;

    &.sticky-main-category {
        top: 72px;
        z-index: 3;
        position: sticky;
    }

    &::-webkit-scrollbar {
        height: 3px;
    }

    .nav-item {
        .nav-link {
            border: 0;
            color: #8A8A8A;
            border-radius: 0;
            position: relative;
            background-color: transparent;

            .fav-icon {
                color: #dc3545;
            }

            &.active {
                color: #ffffff;
                background-color: #dc3545;

                .fav-icon {
                    color: #ffffff;
                    /* Change the color to white for active state */
                }
            }
        }
    }
}

// CHOOSE BRANCH DROP DOWN
.choose-branch-dropdown {
    position: absolute;
    right: 20px;
    top: 15px;

    select {
        font-size: 12px;
    }
}

// PAYMENT MODE STATUS BUTTON
.payment-mode-status-bttn {
    .form-check {
        width: 100%;
        padding: 0;
        position: relative;
        text-transform: capitalize;

        label {
            color: #000;
            width: 100%;
            cursor: pointer;
            padding: 10px 16px;
            border-radius: 5px;
            text-align: center;
            border: 1px dashed #ccc;

            &:hover {
                border-color: #dc3545;
            }
        }

        input {
            display: none;
        }

        input {
            &:checked+label {
                color: #dc3545;
                border-color: #dc3545;
            }
        }
    }

    &.variant-bttn {
        .form-check {
            label {
                border-radius: 25px;

                &:hover {
                    color: #ffffff;
                    background-color: #dc3545;
                }
            }

            input {
                &:checked+label {
                    color: #ffffff;
                    background-color: #dc3545;
                }
            }
        }
    }
}

// LIVE TABLE CARDS
.col-custom-width {
    min-width: 240px;
}

// LIVE TABLE CARD 
.live-table-card.card-one {
    border-radius: 8px;
    overflow: hidden;
    height: 100px;

    border: 1px solid transparent;
    border-left: 8px solid transparent;

    &.status-success {
        min-height: 80px;
    }

    .card-body {
        padding: 10px;
        height: 100%;

        .table-header {
            height: 33.33%;
        }

        .table-body {
            height: 33.33%;
        }

        .table-footer {
            height: 33.33%;

            .table-footer-content {
                .dish-price {
                    span {
                        display: none;
                    }

                    &.paid-class {
                        span {
                            display: inline-block;
                        }
                    }
                }
            }
        }

        .billing-bttn-status {
            display: none;
            align-items: center;

            button {
                i {
                    margin-right: 5px;
                }

                padding: 2px 5px;
                border-radius: 25px;
                margin-right: 3px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .add-item-bttn {
        position: absolute;
        right: 10px;
        bottom: 10px;

        button {
            border-radius: 25px;
            padding: 2px 14px;

            i,
            span {
                vertical-align: middle;
            }
        }
    }

    // ORDER SETTING
    .order-setting {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 2;
        padding: 10px 5px;
        border-radius: 8px;
        justify-content: center;
        transition: 0.1s linear;
        -webkit-transition: 0.1s linear;
        background-color: #ffffff;
        transform: translateY(100%);
        -webkit-transform: translateY(100%);

        &.open {
            transform: translateY(0);
            -webkit-transform: translateY(0);
        }

        .order-setting-close {
            position: absolute;
            top: 2px;
            right: 10px;
        }

        .order-setting-btn {
            display: flex;
            height: 100%;
            flex-wrap: wrap;
            align-items: center;
            width: 90%;

            button {
                i {
                    margin-right: 5px;
                }

                flex: 45% 0;
                padding: 4px 2px;
                border-radius: 25px;
                margin-right: 3px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

    }
}

// HOME DELIVERY WRAPPER
.home-delivery-wrapper {
    .card-one {
        .card-body {
            .order-price-bttn {
                position: absolute;
                bottom: 10px;
                left: 15px;
                right: 15px;
            }
        }
    }
}

// SALES OF TABLE
.sale-on-point-tabs {
    background-color: transparent;

    .nav-item {
        .nav-link {
            color: #343434;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0;
            position: relative;
            text-transform: capitalize;
            border-bottom: 1px solid transparent;
            background-color: transparent;

            .badge-length-web {
                color: #ffffff;
                margin-left: 5px;
                background-color: #eb7782;
            }

            i {
                font-size: 22px;
            }

            &:hover,
            &.active {
                color: #dc3545;
                font-weight: 600;
                border-bottom: 2px solid #dc3545;
                background-color: transparent;

                .badge-length-web {
                    color: #ffffff;
                    background-color: #dc3545;
                }
            }
        }

    }


    &.table-state-tabs {
        .nav-item {
            .nav-link {

                &:hover,
                &.active {
                    color: #000;
                }
            }
        }
    }

    .custom-tab-body {
        margin-top: 20px;
    }
}

// ADDRESS DESCRIPTION.
.address-des {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

// MAIN CATEGORY TABS WRAPPER
.main-category-tabs-wrapper {
    z-index: 2;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    background-color: #fbfcfe;
    border-bottom: 1px solid #e2e5ec;

    &.sticky-main-category {
        top: 72px;
        z-index: 3;
        position: sticky;
    }

    &::-webkit-scrollbar {
        height: 1px;
    }

}

// TABLE SECTION WISE
.table-section-wise {
    background-color: #fbfcfe;

    .table-section-header {
        margin: 0;
        // top: 110px;
        z-index: 2;
        padding: 15px 0;
        // position: sticky;
        background-color: #fbfcfe;
    }
}

// CHOOSE VARIANT BODY
.choose-variant-body {
    .variant-sub-header {
        line-height: 30px;
        padding: 0 10px;
        height: 30px;
        background-color: #F6F6F6;
    }

    .choose-variant-select {
        // --bs-gutter-y: 1rem;
        // display: flex;
        // flex-wrap: wrap;

        .choose-varianr-radio {
            .radio-btn {
                width: 110px;
                height: 80px;
                padding: 10px;
                position: relative;
                border-radius: 8px;
                display: block;
                cursor: pointer;
                border: 1px solid #E2E2E2;

                .variant-price {
                    position: absolute;
                    bottom: 10px;
                    left: 10px;
                    border-radius: 25px;
                    display: inline-block;
                    background-color: #E2E2E2;
                    padding: 2px 12px;
                    font-size: 12px;
                }
            }

            input {
                display: none;

                &:checked+.radio-btn {
                    border-color: #ba2618;

                    h3 {
                        color: #ba2618;
                    }

                    .variant-price {
                        background-color: #ba2618;
                        color: #ffffff;
                    }
                }
            }
        }
    }

    .add-topping {
        .form-check {
            label {
                cursor: pointer;
                margin-left: 5px;
                vertical-align: middle;
            }

            input {
                width: 20px;
                height: 20px;

                &:checked {
                    color: #dc3545;
                    border-color: #dc3545;
                    background-color: #dc3545;
                }

                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }
        }

        .qty {
            align-items: center;
            width: 45%;
            display: none;
            position: absolute;
            right: 50px;

            &.view-qty {
                display: flex;
            }
        }
    }
}

// CHOOSE DISOCUN COUPON
.choose-coupon-radio {
    display: flex;
    cursor: pointer;
    align-items: center;
    padding: 15px 10px;
    margin-bottom: 10px;
    box-shadow: 0 2px 5px 1px rgba(64, 60, 67, .16);

    .radio-btn {
        width: 100%;
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h3 {
            .applied {
                display: none;
                margin-left: 5px;
            }
        }
    }

    input[type="radio"] {
        width: 20px;
        height: 20px;

        &:checked {
            color: #dc3545;
            border-color: #dc3545;
            background-color: #dc3545;

            &+.radio-btn {
                margin-left: 10px;

                .applied {
                    display: inline-block;
                }
            }
        }

        &:focus {
            outline: none;
            box-shadow: none;
        }

    }


}

.total-bills-bottom {
    padding: 10px 15px;
    position: relative;
    box-shadow: 0px -1px 5px 1px rgba(64, 60, 67, 0.16);


    .totla-bills-toggle {
        line-height: normal;

        i {
            font-size: 24px;
            display: inline-block;
            vertical-align: middle;
        }
    }

    .totla-bills-list {
        padding: 0;
        display: none;
        margin-bottom: 5px;

        span {
            font-size: 12px;
        }

        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    &.toggled {
        .totla-bills-list {
            display: block;
        }

        .totla-bills-toggle {
            i {
                transform: rotate(180deg);
                -webkit-transform: rotate(180deg);
            }
        }
    }
}

// APPLY CHARGE LIST
.aplly-charge-list {
    border: 0;
    margin-bottom: 10px;
    box-shadow: 0 2px 5px 1px rgba(64, 60, 67, .16);
}

.block-pages {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 4;
    display: flex;
    align-items: center;
    position: absolute;
    justify-content: center;
    background: rgb(243 243 243 / 84%);

    .locks-icon {
        img {
            width: 100px;
        }
    }
}

.table-name-text {
    white-space: nowrap;
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card-loader {
    z-index: 99;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    background-color: rgb(0 0 0 / 50%);
}

.card-border.card {
    border: 1px solid #ccc;
    border-bottom: 0;

    &:last-child {
        border-bottom: 1px solid #ccc;
    }
}

.added-item-card.card {
    border: 1px solid #0cb785;
}

.freeze-tab {
    left: 0;
    right: 0;
    bottom: 35px;
    top: 52px;
    z-index: 8;
    position: absolute;
}

.custom-name-dropdown {
    width: 100%;
    position: absolute;
    overflow-y: auto;
    min-height: 100px;
    max-height: 300px;
}

.customer-list-spinner {
    text-align: center;

    div {
        width: 1.5rem;
        height: 1.5rem;
    }
}

@media only screen and (max-width: 1025px) {
    .live-table-card {
        &.card-one {
            .card-body {
                .table-header {
                    .table-name-text {
                        width: 90px !important;
                    }
                }
            }
        }
    }

}

@media only screen and (max-width: 1024px) {
    .main-category-tabs-wrapper {
        &.sticky-main-category {
            overflow-x: auto;
            flex-wrap: nowrap;

            .sale-on-point-tabs {
                white-space: nowrap;
            }
        }
    }

    .home-delivery-wrapper {
        .card-one {
            .card-body {
                h4 {
                    font-size: 12px !important;
                }

                .order-price-bttn {
                    bottom: 5px;
                }
            }
        }
    }
}

@media only screen and (max-width: 820px) {
    .add-item-modal-col.row {
        overflow-y: auto;
      
        .offcanvas-header {
            top: 0;
            z-index: 5;
            position: sticky;
            background-color: #fff;
        }

        .col-md-8,
        .col-md-4 {
            width: 100%;

            .sub-category-tabs {
                .sub-menu-content {
                    height: auto;
                }
            }

            .my-card {
                height: auto;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .block-pages {
        width: 100%;
    }
}

@media only screen and (max-width: 480px) {
    .live-table-card {
        &.card-one {
            min-height: 88px;

            .card-body {
                .table-header {
                    .table-name-text {
                        width: 200px !important;
                    }
                }
            }
        }
    }

    .main-category-tabs-wrapper {
        overflow-x: auto;

        .sale-on-point-tabs {
            white-space: nowrap;
        }
    }

}